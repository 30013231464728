<template>
  <div>
    <vx-card
      title="حدث خطأ"
      class="mb-2"
      title-color="danger"
      v-if="Object.keys(errors_values).length"
    >
      <div v-for="(key, index) in Object.keys(errors_values)" :key="index">
        <span
          v-for="(error, index) in errors_values[key]"
          :key="index"
          class="text-danger"
        >
          {{ error }}
        </span>
      </div>
    </vx-card>
    <vx-card title="تعديل طلبية " title-color="primary">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label">للمستخدم</label>
          <v-select
            v-model="order.user_id"
            name="to-the-user"
            v-validate="'required'"
            data-vv-as="للمستخدم"
            label="username"
            :reduce="(item) => item.id"
            :options="users"
          ></v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('order-items.to-the-user')"
            >{{ errors.first("order-items.to-the-user") }}</span
          >
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label mt-5">تاريخ التوصيل</label>
          <datepicker
            class="w-full"
            format="dd/MM/yyyy"
            v-validate="'required'"
            data-vv-as="تاريخ التوصيل"
            name="delivery_date"
            v-model="order.delivery_date"
            tabindex="2"
          ></datepicker>
          <span
            class="text-danger text-sm"
            v-show="errors.has('delivery_date')"
            >{{ errors.first("delivery_date") }}</span
          >
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label mt-5">وسيلة الدفع</label>
          <v-select
            v-model="order.payment_method_id"
            name="payment_method"
            v-validate="'required'"
            data-vv-as="وسيلة الدفع"
            label="lable"
            :reduce="(item) => item.value"
            :options="paymentMethods"
          ></v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('payment_method')"
            >{{ errors.first("payment_method") }}</span
          >
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label mt-5">حالة الطلب</label>
          <v-select
            v-model="order.order_status_id"
            name="order_status"
            v-validate="'required'"
            data-vv-as="حالة الطلب"
            label="label"
            :reduce="(item) => item.value"
            :options="statusOptions"
          ></v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('order_status')"
            >{{ errors.first("order_status") }}</span
          >
        </div>

        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label mt-5">سعر التوصيل</label>
          <vs-input
            data-vv-as="سعر التوصيل"
            v-validate="'numeric|min:0'"
            type="number"
            class="w-full"
            name="delivery_price"
            v-model="order.delivery_price"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('delivery_price')"
            >{{ errors.first("delivery_price") }}</span
          >
        </div>

        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label mt-5">سعر الدعم</label>
          <vs-input
            data-vv-as="سعرالدعم"
            v-validate="'numeric|min:0'"
            type="number"
            class="w-full"
            name="support"
            v-model="order.support"
          />
          <span class="text-danger text-sm" v-show="errors.has('support')">{{
            errors.first("support")
          }}</span>
        </div>

        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-2">
              <label class="vs-input--label">فترة التوصيل من</label>
              <div>
                <vue-timepicker
                  style="width: 100%"
                  v-validate="'required'"
                  name="من الفترة"
                  dir="ltr"
                  v-model="order.time_from"
                  format="HH:mm"
                ></vue-timepicker>
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('من الفترة')"
                >{{ errors.first("من الفترة") }}</span
              >
            </div>

            <div class="vx-col md:w-1/2 w-full mb-2">
              <label class="vs-input--label">فترة التوصيل الي</label>
              <div>
                <vue-timepicker
                  style="width: 100%"
                  v-validate="'required'"
                  name="الي الفترة"
                  dir="ltr"
                  v-model="order.time_to"
                  format="HH:mm"
                ></vue-timepicker>
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('الي الفترة')"
                >{{ errors.first("الي الفترة") }}</span
              >
            </div>

            <div class="vx-col md:w-1/2 w-full mb-2">
              <label class="vs-input--label mt-5">ملاحظة</label>
              <vs-input
                data-vv-as="ملاحظة"
                v-validate="''"
                type="text"
                class="w-full"
                name="note"
                v-model="order.note"
              />
              <span class="text-danger text-sm" v-show="errors.has('note')">{{
                errors.first("note")
              }}</span>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-2">
              <label class="vs-input--label">مندوب التوصيل</label>
              <v-select
                v-model="order.delivery_id"
                name="delivery_id"
                v-validate="'required'"
                data-vv-as="المندوب"
                label="username"
                :reduce="(item) => item.id"
                :options="delivery"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('order-items.delivery_id')"
                >{{ errors.first("order-items.delivery_id") }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <vs-divider></vs-divider>
      <div class="vx-row mt-5 mb-5">
        <div class="vx-col w-full mb-2" style="z-index: 1">
          <vs-table :data="order_items" v-if="order_items">
            <template slot="thead">
              <vs-th>إسم المنتج او الباقة</vs-th>

              <vs-th>السعر</vs-th>
              <vs-th>بعد التخفيض</vs-th>
              <vs-th>سعر التكلفة</vs-th>

              <vs-th>الكمية المحددة</vs-th>
              <vs-th>المجموع</vs-th>
              <vs-th>العمليات</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ tr.name }}</vs-td>

                <vs-td>
                  <vs-input
                    type="number"
                    class="w-1-2"
                    label-placeholder="السعر"
                    v-model="tr.price"
                    @blur="submit(false)"
                  />
                </vs-td>
                <vs-td>
                  <vs-input
                    type="number"
                    class="w-1-2"
                    v-model="tr.new_price"
                    @blur="submit(false)"
                  />
                </vs-td>
                <vs-td>
                  <vs-input
                    type="number"
                    class="w-1-2"
                    label-placeholder="السعر"
                    v-model="tr.original_price"
                    @blur="submit(false)"
                  />
                </vs-td>

                <vs-td>
                  <vs-input
                    :disabled="tr.package_id != null"
                    v-validate="{
                      required: tr.product_id != null ? true : false,
                      max_value:
                        tr.product_id != null ? tr.product_quantity : 1,
                    }"
                    type="number"
                    name="quantity"
                    v-model="tr.quantity"
                    class="w-1/2"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('quantity')"
                    >{{ errors.first("quantity") }}</span
                  >
                </vs-td>
                <vs-td v-if="tr.product_id"
                  >{{
                    tr.new_price > 0
                      ? tr.new_price * tr.quantity
                      : tr.price * tr.quantity
                  }}
                  د.ل
                </vs-td>
                <vs-td v-if="tr.package_id">{{ tr.total }} د.ل</vs-td>

                <vs-td>
                  <feather-icon
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="ml-2"
                    @click.stop="deleteItem(tr, indextr)"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-table class="w-1/2" data="items" v-if="order_items">
            <template>
              <vs-tr>
                <vs-th>مجموع الطلبية</vs-th>
                <vs-td> {{ sub_total }} د.ل</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-th>قيمة الكوبون المستخدم</vs-th>
                <vs-td>
                  {{
                    order.coupon_value
                      ? order.coupon_value + "د.ل"
                      : "لم يتم استخدام كوبون"
                  }}
                </vs-td>
              </vs-tr>
              <vs-tr>
                <vs-th>التخفيض</vs-th>
                <vs-td>
                  <vs-input
                    type="number"
                    class="w-1/3"
                    name="discount"
                    v-model="order.discount"
                  />
                </vs-td>
              </vs-tr>
              <vs-tr>
                <vs-th>المدفوع</vs-th>
                <vs-td>
                  {{
                    order.payment_method_id !== 1 ? order.paid : "مدفوع نقدي"
                  }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>

      <div class="vx-row mt-2 w-full align-items-center">
        <div class="vx-col my-3 sm:w-1/2 w-full mb-2 flex">
          <label class="vs-input--label"> اختر رقم sku</label>
          <v-select
            name="sku"
            class="w-full"
            data-vv-as="sku"
            data-vv-scope="order-items"
            label="sku"
            :options="products"
            @input="chooseProduct"
            :tabindex="4"
            :filterable="true"
          ></v-select>
        </div>
        <div class="vx-col mt-3 sm:w-1/2 w-full mb-6 seperator flex">
          <label class="vs-input--label">اختر الباقة</label>
          <v-select
            class="w-full"
            name="chosen_package"
            data-vv-as="الباقة"
            label="name"
            @input="choosePackage"
            :options="packages"
            :tabindex="4"
            :filterable="true"
          ></v-select>
        </div>
      </div>

      <div class="vx-row mt-8 text-right">
        <div class="vx-col sm:w-full w-full mb-6">
          <vs-button
            class="mr-5 text-lg py-4"
            @click="
              $router.push({
                name: 'orders',
                query: { page: $route.query.page },
              })
            "
            color="warning"
            type="border"
            >إلغاء</vs-button
          >
          <vs-button
            class="mr-5 text-lg px-16 py-4"
            @click="submit"
            color="success"
            type="filled"
            >حفظ</vs-button
          >
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import moduleOrder from "@/store/orders/moduleOrder.js";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import moduleUser from "@/store/users/moduleUser.js";
export default {
  components: { vSelect, Datepicker, VueTimepicker },
  data() {
    return {
      order: {
        time_from: "",
        time_to: "",
        id: "",
        order_status_id: "",
        payment_method_id: "",
        user_id: "",
        delivery_id: "",
        delivery_date: "",
        delivery_price: "",
        total: "",
        coupon_value: "",
        discount: 0,
        items: [],
      },
      errors_values: {},
      order_items: [],
      new_order_items: [],
      deleted_order_items: [],
      delivery: [],
      statusOptions: [
        {
          label: "قيد المراجعة",
          value: 1,
        },
        {
          label: "قيد التجهيز",
          value: 2,
        },
        {
          label: "تم التوصيل",
          value: 3,
        },
        {
          label: "تم الرفض",
          value: 4,
        },
      ],
    };
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    products() {
      return this.$store.state.products;
    },
    packages() {
      return this.$store.state.packages;
    },
    paymentMethods() {
      return this.$store.state.order.paymentMethods;
    },
    prices() {
      if (this.order_items.length) {
        return this.order_items.map((item) => {
          return {
            price: item.new_price > 0 ? item.new_price : item.price || 0,
            package_total: item.total || 0,
            quantity: item.quantity,
          };
        });
      }
    },
    total() {
      if (this.prices) {
        return this.prices.reduce(function (s, e) {
          return (
            s +
            (parseFloat(e.price)
              ? parseFloat(e.price) *
                (parseFloat(e.quantity) ? parseFloat(e.quantity) : 1)
              : 0) +
            parseFloat(e.package_total)
          );
        }, 0);
      }
    },
    sub_total() {
      return this.total - this.order.discount - this.order.coupon_value;
    },
  },
  methods: {
    getData() {
      this.$store
        .dispatch("order/editOrder", this.$route.params.id)
        .then((response) => {
          this.order.id = response.data.data.id;
          this.order.order_status_id = response.data.data.status.id;
          this.order.delivery_price = response.data.data.delivery_price;
          this.order.support = response.data.data.support || 0;
          this.order.payment_method_id = response.data.data.payment_method.id;
          this.order.user_id = response.data.data.user
            ? response.data.data.user.id
            : null;
          this.order.delivery_date = response.data.data.delivery_date;
          this.order.total = response.data.data.total;
          this.order.discount = response.data.data.discount;
          this.order.note = response.data.data.note;
          this.order.delivery_id = response.data.data.delivery_id;

          this.order.coupon_value = response.data.data.coupon_value || 0;
          this.order_items = response.data.data.items;
          this.order.time_from = response.data.data.time_from;
          this.order.time_to = response.data.data.time_to;
          this.order.paid = response.data.data.paid;
        });
    },
    chooseProduct(product) {
      let new_product = {
        product_id: product.id,
        name: product.name,
        price: product.price,
        new_price: product.new_price,
        product_quantity: product.quantity,
        quantity: 1,
      };
      this.new_order_items.push(new_product);
      this.order_items.push(new_product);
    },
    choosePackage(chosen_package) {
      let new_package = {
        package_id: chosen_package.id,
        name: chosen_package.name,
        total: chosen_package.total,
        quantity: 1,
      };
      this.new_order_items.push(new_package);
      this.order_items.push(new_package);
    },
    async submit(reroute = true) {
      let old_items = this.order_items.filter((item) => {
        if (item.order_id) {
          return item;
        }
      });
      let result = await this.$validator.validateAll().then((result) => {
        return result;
      });
      if (result == false) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يرجى ملئ كافة الحقول",
          color: "danger",
        });
        return false;
      }
      if (this.order_items.length < 1) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يرجى إضافة منتج علي الأقل",
          color: "danger",
        });
        return false;
      }

      let formdata = new FormData();
      formdata.set("order_id", this.order.id);
      formdata.set("total", this.order.total);
      formdata.set("status", this.order.order_status_id);
      formdata.set("discount", this.order.discount);
      formdata.set("delivery_price", this.order.delivery_price);
      formdata.set("support", this.order.support);
      formdata.set("note", this.order.note);

      formdata.set("delivery_date", this.formatDate(this.order.delivery_date));
      formdata.set("payment_method", this.order.payment_method_id);
      formdata.set("user", this.order.user_id);
      formdata.set("deleted", JSON.stringify(this.deleted_order_items));
      formdata.set("new", JSON.stringify(this.new_order_items));
      formdata.set("items", JSON.stringify(old_items));

      formdata.set("time_from", this.order.time_from);
      formdata.set("time_to", this.order.time_to);
      formdata.set("delivery_id", this.order.delivery_id);

      this.$store
        .dispatch("order/updateOrder", formdata)
        .then(() => {
          this.$vs.notify({
            title: "نجاح",
            text: "تمت تعديل الطلب",
            color: "success",
          });
          reroute
            ? this.$router.push({
                name: "orders",
                query: { page: this.$route.query.page },
              })
            : null;
        })
        .catch((err) => {
          this.errors_values = err.response.data.errors;
          this.$vs.notify({
            title: "حدث خطأ",
            text: err.response.data.message,
            color: "danger",
          });
        });
    },

    deleteItem(item, index) {
      if (item.order_id) {
        this.deleted_order_items.push({ id: item.id });
      }
      this.order_items.splice(index, 1);
    },
  },
  created() {
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("user", moduleUser);
      moduleUser.isRegistered = true;
    }
    if (!moduleOrder.isRegistered) {
      this.$store.registerModule("order", moduleOrder);
      moduleOrder.isRegistered = true;
    }
    this.getData();
    this.$store.dispatch("getAllUsers");
    this.$store.dispatch("getAllProducts");
    this.$store.dispatch("getAllPackages");
    this.$store.dispatch("order/getPaymentMethods");
    this.$store.dispatch("user/fetchDelivery",{'all':true}).then((response) => {
      this.delivery = response.data.delivery.data;
    });
  },
};
</script>

<style>
.display-time {
  width: 100% !important;
  height: 37px !important;
  border-radius: 3px;
}

.seperator {
  border-left: 1px solid rgb(204, 204, 204);
}

.align-items-center {
  align-items: center;
}
</style>
