import axios from '@/axios.js'
export default {
  fetchUsers({commit},params){
    return new Promise((resolve, reject)=>{
      axios.get('api/dashboard/users/index',{params:params})
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },

  fetchAdmins({commit}){
    return new Promise((resolve, reject)=>{
      axios.get('api/dashboard/users/admins-index')
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },
  fetchDelivery({commit}){
    return new Promise((resolve, reject)=>{
      axios.get('api/dashboard/users/delivery-index')
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },

  getRoles({commit}){
    return new Promise((resolve, reject)=>{
      axios.get('api/dashboard/users/roles')
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },

  changeStatus({commit},payload){
    return new Promise((resolve,reject)=>{
      axios.post(`api/dashboard/users/change-status/${payload.get('id')}`,payload)
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  },

  createAdmin({commit},payload){
    return new Promise((resolve,reject)=>{
      axios.post(`api/dashboard/users/store`,payload)
      .then((response)=>{
        resolve(response)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }
}
